<template>
    <div>
        <section class='trbaner'>
        <div class="foxj">
            <div class='am-container'>
                <div class='txt'>
                    <div class="am-vertical-align-middle">
                     <p class="tit">新一代智能配电房环境监控系统</p>
                     <p class="note">云端管理+本地管理，融合物联网技术，独创一体化设计理念，化繁为简，让配电房的一切尽在掌握之中！</p>
                </div>
                </div>
            <img src='~images/配电房监控1.png'>
            </div>
        </div>
        </section>
        <section class="jf_xtjj ">
            <div class="particles">
                <div class="am-container">
                    <div class="ue-title">
                        <p class="tit">系统简介</p>
                        <p class="note">地球村新一代智能配电房环境监控系统，借助物联网平台，采用云端管理+本地管理<br>
模式，并独创一体化(ALL IN ONE)设计理念</p>
                    </div>
                    <div class="danimg">
                        <img src="~images/系统简介1.png">
                    </div>
                </div>
            </div>
        </section>
        <section class="zh-sys">
            <div class="am-container">
            <div class="txt">
                <p class="tit">智能配电房环境系统</p>
                <p class="note">实现7×24×365全面集中监控和管理，保障配电房环境及设备安全高效运行，最终实<br>
现“集中监控、精确定位故障、无人值守、高效管理”的配电房管理模式</p>
            </div>
            <div class="zheys-list">
                <div class="teck-advantage-center">
                    <i>
                        <img id="img3" src="~images/yuan.png">
                    </i>
                </div>
                <ul>
                    <li class="sb1">
                        <a>
                            <i><img src="~/images/7fb805963b6036aa.png" /></i>
                            <div class="tex">
                                <p class="tit">监控平台一体化</p>
                                <p class="note">将配电房内的动力、环境、安防及消防系统分别纳入统一的综合监控平台，涵盖：供配电、UPS、蓄电池、温湿度、空调、漏水、新风机、烟感等</p>
                            </div>
                        </a>
                    </li>
                    <li class="sb2">
                        <a>
                            <i><img src="~/images/网络供电.png" /></i>
                            <div class="tex">
                                <p class="tit">网络供电一体化</p>
                                <p class="note">主机集成不间断电源管理及后备锂电，采用独立组网、集中供电部署方式，确保配电房电力和网络瘫痪情况下监控系统仍能稳定可靠运行</p>
                            </div>
                        </a>
                    </li>
                    <li class="sb3">
                        <a>
                            <i><img src="~/images/软件硬件.png" /></i>
                            <div class="tex">
                                <p class="tit">软件硬件一体化</p>
                                <p class="note">主机搭载ARM处理器+Linux+综合监控系统，提供便捷的WEB服务，具备实时监控、数据分析、报表统计，设备管理，警告和事件管理等服务</p>
                            </div>
                        </a>
                    </li>
                    <li class="sb4">
                        <a>
                            <i><img src="~/images/告警通知.png" /></i>
                            <div class="tex">
                                <p class="tit">告警通知一体化</p>
                                <p class="note">主机集成短信电话及音频处理硬件，减少额外硬件和复杂连接配置，实现电话语音、短信、邮件、现场广播及声光报警多方位告警通知</p>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
            </div>
        </section>
        <section class="xtgn">
            <div class="am-container">
                <div class="xt">
                    <div>
                        <div class="ul-iti">
                            <p class="title">系统功能</p>
                        </div>
                    </div>
                    <div class="xggn-list">
                        <ul class="am-avg-md-3">
                            <li>
                                <i><img src="~/images/综合监控.png"></i>
                                <div class="txt">
                                    <p class="tit">综合监控</p>
                                    <p class="note">可视化管理模式，对配电房动力、环境、消防、安防等基础设施监控全覆盖，无所不及</p>
                                </div>
                            </li>
                            <li>
                                <i><img src="~/images/云端管理.png"></i>
                                <div class="txt">
                                    <p class="tit">云端管理</p>
                                    <p class="note">除本地监控管理外，还可以通过云平台实现分布式集中监控管理，随时随地掌握监控动态</p>
                                </div>
                            </li>
                            <li>
                                <i><img src="~/images/统计报表.png"></i>
                                <div class="txt">
                                    <p class="tit">统计报表</p>
                                    <p class="note">关键指标的数据统计及趋势图分析，细致而全面地分析配电房的运行状况</p>
                                </div>
                            </li>
                            <li>
                                <i><img src="~/images/实时报警.png"></i>
                                <div class="txt">
                                    <p class="tit">实时报警</p>
                                    <p class="note">电话、短信、邮件、广播、声光多种报警方式无人值守轻松实现，安全可靠</p>
                                </div>
                            </li>
                            <li>
                                <i><img src="~/images/设备报警.png"></i>
                                <div class="txt">
                                    <p class="tit">设备管理</p>
                                    <p class="note">监控设备节点无限制，随需而配，并可灵活对每个监控指标策略及联动设置</p>
                                </div>
                            </li>
                            <li>
                                <i><img src="~/images/日志管理.png"></i>
                                <div class="txt">
                                    <p class="tit">日志管理</p>
                                    <p class="note">提供完整的事件记录、报警记录、系统日志及关键数据历史记录，实现调查取证、历史分析提升威胁管理和应急响应能力</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
                <section class="ld"> 
            <div class="am-container">
                <div class="ue-title">
                    <p>系统联动功能</p>
                    <!-- <p class="note">提供从设备接入到各行业应用的一站式物联网服务，释放万物互联的价值</p> -->
                </div>
                <img src="~images/联动图.png">
                <!-- <div class="pt-list">
                    <ul class="am-avg-md-3">
                        <li>
                            <i><img src="~/images/空调 (1).png"></i>
                            <div class="txt">
                                <p class="tit">温度值传感器联动空调</p>
                                <p class="note">&nbsp;√&nbsp;当探测到室内温度高于38度时，联动空调进行制冷。<br>&nbsp;√&nbsp;当探测到室内温度降至30度时，关闭空调停止制冷.</p>
                            </div>
                        </li>
                        <li>
                            <i><img src="~/images/风机 (3).png"></i>
                            <div class="txt">  
                                <p class="tit">温度值传感器联动风机</p>
                                <p class="note">&nbsp;√&nbsp;当探测到室内温度高于38度时，联动风机启动进行散热。<br>&nbsp;√&nbsp;当探测到室内温度降至30度时，关闭风机</p>
                            </div>
                        </li>
                        <li>
                            <i><img src="~/images/水泵 (1).png"></i>
                           <div class="txt">
                                <p class="tit">水位传感器联动水泵</p>
                                <p class="note">&nbsp;√&nbsp;当探测到水位高于35厘米时，联动水泵启动抽水。<br>&nbsp;√&nbsp;当探测到水位低于10厘米时，关闭水泵停止抽水</p>
                            </div>
                        </li>
                        <li>
                          <i><img src="~/images/风机 (2).png"></i>
                            <div class="txt">
                                   <p class="tit">有害气体联动风机</p>
                                   <p class="note">&nbsp;√&nbsp;当室内六氟化硫、臭氧等气体浓度超标或氧气浓度过低时，联动风机排风换气。<br>&nbsp;√&nbsp;当气体浓度恢复正常时，关闭风机</p>
                               </div>
                           </li>
                           <li>
                               <i><img src="~/images/设备_除湿器 (1).png"></i>
                               <div class="txt">
                                   <p class="tit">湿度值联动除湿机</p>
                                   <p class="note">&nbsp;√&nbsp;当温湿度传感器或除湿机自身检测到湿度高于75%时，启动除湿机进行除湿。<br>&nbsp;√&nbsp;当湿度低于50%时，关闭除湿机</p>
                               </div>
                           </li>
                           <li>
                               <i><img src="~/images/烟感 (1).png"></i>
                               <div class="txt">
                                   <p class="tit">烟感传感器联动风机</p>
                                   <p class="note">烟感或明火探测器探测到火灾时，将报警信号发送至中心平台及移动终端并强制风机关闭</p>
                               </div>
                           </li>
                            <li>
                          <i><img src="~/images/门禁.png"></i>
                            <div class="txt">
                                   <p class="tit">门禁联动灯光系统</p>
                                   <p class="note">&nbsp;√&nbsp;当门禁开门或红外传感器探测到异常时，联动灯光开启照明。<br>&nbsp;√&nbsp;当远程查看视频监控时，可远程打开灯光进行照明</p>
                               </div>
                           </li>
                           <li>
                               <i><img src="~/images/红外 (1).png"></i>
                               <div class="txt">
                                   <p class="tit">门禁联动红外报警</p>
                                   <p class="note">当门禁正常刷卡或指纹打开后，联动红外传感器撤销红外报警;当红外无触发新报警15分钟后，自动恢复触发报警模式。</p>
                               </div>
                           </li>
                           <li>
                               <i><img src="~/images/摄像头 (1).png"></i>
                               <div class="txt">
                                   <p class="tit">报警信号联动摄像头</p>
                                   <p class="note">现场烟感、明火、漏水.红外等探测器触发报警信号时，将报警信号发送至中心平台及移动终端，并联动摄像机启动报警录像</p>
                               </div>
                           </li>
                       </ul>
                   </div> -->
            </div>
        </section>
        <section class="xtjg">
            <div class="am-container">
                <div class="ul-iti">
                    <p class="title">系统拓扑图</p>
                 </div>
                 <div class="danimg">
                     <img src="~images/系统架构1.png">
                 </div>
            </div>
        </section>
        <section class="xttd">
            <div class="am-container">
                <div class="ue-title">
                    <p class="tit">系统特点</p>
                </div>
                <div class="tdlist">
                    <ul>
                        <li>
                            <div class="item">
                            <i><img src="~/images/软硬一体.png"></i>
                            <p class="tit">软硬一体，化繁为简</p>
                            <p class="note">高度集成电话短信等硬件，减少额外服务器及其它设备组件配备，减少连接数和故障点，提高系统稳定性</p>
                            </div>
                        </li>
                        <li>
                            <div class="item">
                            <i><img src="~/images/c18906f5580b977d.png"></i>
                            <p class="tit">B/S构架，方便管理</p>
                            <p class="note">浏览器访问，无需软件安装和维护，分布和共享性强，方便管理和使用</p>
                            </div>
                        </li>
                        <li>
                            <div class="item">
                            <i><img src="~/images/2d66e8851a671551.png"></i>
                            <p class="tit">云端接入，随地掌控</p>
                            <p class="note">摆脱地域限制，便于分布式集中管理，随时随地掌握监控状态，让管理更加轻松简单</p>
                            </div>
                        </li>
                        <li>
                            <div class="item">
                            <i><img src="~/images/beca14046cbc8cca.png"></i>
                            <p class="tit">界面简约，极致体验</p>
                            <p class="note">HTML5+CSS3布局，框架结构层次清晰分明，时尚，简约，轻松，实用，易用，易懂</p>
                            </div>
                        </li>
                        <li>
                            <div class="item">
                            <i><img src="~/images/0e5a967371b33f6d.png"></i>
                            <p class="tit">双主视图，视角切换</p>
                            <p class="note">3D+WIN10风格双主视图，不同视角全局呈现，实时直观地模拟现实场景与环境，方便运维管理</p>
                            </div>
                        </li>
                        <li>
                            <div class="item">
                            <i><img src="~/images/b832e6cb0f55fe90.png"></i>
                            <p class="tit">接口丰富，连接便捷</p>
                            <p class="note">丰富多类外设接口，适应各种场合应用，让传感设备连接更轻松快捷，维护更简单方便</p>
                            </div>
                        </li>
                        <li>
                            <div class="item">
                            <i><img src="~/images/a1872d5c181ee9a2.png"></i>
                            <p class="tit">短信电话，智能告警</p>
                            <p class="note">支持多联系人，可选短信、电话语音告警。创新事件过滤算法，具有告警提示和恢复提示，避免重复告警</p>
                            </div>
                        </li>
                        <li>
                            <div class="item">
                            <i><img src="~/images/54d2286ef8de8de9 (1).png"></i>
                            <p class="tit">双电冗余，安全可靠</p>
                            <p class="note">双电源设计，安全等级更高，后备锂电，超长续航，保障系统的安全性和可靠性</p>
                            </div>
                        </li>
                        <li>
                            <div class="item">
                            <i><img src="~/images/4529d2787a5be160.png"></i>
                            <p class="tit">视频图像，集中管理</p>
                            <p class="note">可兼容市面主流品牌网络摄像头和视频设备，进行集中整合，便于全面监控管理</p>
                            </div>
                        </li>
                        <li>
                            <div class="item">
                            <i><img src="~/images/a53c153f71d28540.png"></i>
                            <p class="tit">监控对象，随需而配</p>
                            <p class="note">监控对象无数量节点限制，根据所需灵活添加和删减，易于后续升级扩展</p>
                            </div>
                        </li>
                        <li>
                            <div class="item">
                            <i><img src="~/images/d59b0340070b298c.png"></i>
                            <p class="tit">智能设备，适配兼容</p>
                            <p class="note">内置设备类型库和驱动库，10年沉淀，支持多种通讯协议，可对接各大品牌智能设备</p>
                            </div>
                        </li>
                        
                    </ul>
                </div>
            </div>
        </section>
        <section class="xtyy">
            <div class="am-container">
                <div class="ul-iti">
                    <p class="title">系统应用</p>
                 </div>
                 <div class="danimg">
                     <img src="~images/系统应用2.png">
                 </div>
            </div>
        </section>
        <section class="znbj">
            <div class="am-container">
                <div class="ue-title">
                    <p>智能报警</p>
                </div>
                <div class="znbjer">
                <div class="left">
                    <div id="buttons">
                        <ul >    
                            <li v-for="(item,index) in imgbox" :key="index" @mouseenter="gotoPage(index)" :class="{on:currentIndex == index}"> 
                                <i>
                                    <img :src="item.imgView">
                                </i>
                                <p> {{item.pp}}</p>
                            </li>
                        </ul>           
                    </div>
                </div>
                <div class="right">
                    <div id="container"> 
                        <div id="list" >
                            <img :src="dataList[currentIndex]">
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </section>
        <section class="product ">
            <div class="am-container">
                <div class="ue-title">
                    <p class="tit>">相关产品</p>
                </div>
                <div class="productList">
                    <div>
                    <ul class="am-avg-md-3">
                        <li v-for="(item,index) in data" :key='index' @click="func(item)" >
                            <a href="javascript:">
                            <i> <img :src="/dqcsys/+item.furl"></i>
                            <div class="txt">
                                <p class="tit">{{item.product_name}}</p>
                                <p class="note">{{item.product_model}}</p>
                            </div>
                            </a>
                        </li>
                    </ul>
                    </div>
                    <div class="more">
                        <a href="/Product">更多</a>
                    </div>
                </div>
            </div>
        </section>
        <!-- <section class="homed">
            <p>有疑问或想了解更多，请咨询：{{this.$parent.data.company_tel}}</p>
        </section> -->
    </div>
</template>

<script>
import {getList}from 'api/product'

export default {
    data () {
        return {
            dataList : [
            require('../assets/images/电话警告.jpg'),
            require('../assets/images/短信警告.jpg'),
            require('../assets/images/邮件警告.jpg'),
            require('../assets/images/广播警告.jpg'),
            require('../assets/images/声光警告.jpg')
            ],
            data:[],
            searchInfo: {
                page_no: 1,
                page_size: 8,
            },
            imgbox: [
                { id:0,imgView: require ('../assets/images/ba358289687b0702.png'), pp: '电话报警'},
                { id:1, imgView: require ('../assets/images/08ed82fe85308e6e (1).png'), pp: '短信警告'},
                { id:2,imgView: require ('../assets/images/8c65aaea145c2c2b.png'), pp: '邮件警告'},
                { id:3,imgView: require ('../assets/images/92794b6076c17ab1.png'), pp: '广播警告'},
                { id:4,imgView: require ('../assets/images/27b177e605db6dc3.png'), pp: '声光警告'}
            ],
            currentIndex:0,
            timer:''
        }
    }, methods: {
        gotoPage(index) {
            this.currentIndex = index;
        },    
        start () {
        this.timer = setInterval(() => {
            this.gotoPage(this.nextIndex)
        },5000)
        },
        getList() {
        this.isShowloading = true;
         console.log(this.searchInfo);
        getList(this.searchInfo).then((res) => {
        console.log(res);
        this.data = res.data.list;
        console.log(this.data)
      });},        
        func(item) {
        this.$router.push({name: 'product-2',params:{ id:item.fuid}});
      },
          stop () {
        clearInterval(this.timer)
            },
            reset () {
                this.timer = 0
            },
    },
    mounted () {
        this.timer = setInterval(() => {
            this.gotoPage(this.nextIndex)
        },5000)
        this.getList()
    },
    beforeDestroy() {
        this.stop()
    },

    computed: {
        nextIndex () {
            if(this.currentIndex == 4) {
                return 0;
            }else {
                return this.currentIndex + 1;
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.trbaner {
    position: relative;
    background: #272733;
    min-height: 420px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding-top: 30px;
}
.trbaner img {
    width: 100%;
    width: 40%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    vertical-align: middle;
    border: 0;
    top:50px;
    float: right;
    position: relative;
}
.trbaner .foxj {
    position:absolute;
    width: 100%;
    height: 100%;
    z-index: 22;
}
.am-container {
    position: relative;
    height: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;
    max-width: 1230px;
}
.foxj .txt {
    width: 46%;
    color: #fff;
    position: absolute;
    left: 1.5rem;
    height: 100%;
    top: 0;
    text-align: left;
}
.foxj .txt .tit{
    font-size: 32px;
    margin-bottom: 15px;
    color: #fff;
}
.foxj .note{
    font-size: 16px;
    margin-bottom: 20px;
    line-height: 2;
    color: #fff;
}
.am-container::after{
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.foxj .txt .am-vertical-align-middle {
       position: relative;
       top: 30%;
}
.jf_xtjj  {
    position: relative;
    background-color: #f8fcff;
    padding: 80px 0;
    .particles {
        .am-container {
            max-width: 1360px;
            position: relative;
            z-index: 999;
            .tit {
                font-size: 32px;
            }
            .note {
                font-size: 16px;
                color: #999;
                word-break: break-all;
                word-wrap: break-word;
                white-space: pre-wrap;
            }
            .danimg {    
                margin-top: 40px;
                text-align: center;
                img {
                    width: 100%;
                }
                
            }
        }
    }
}
.zh-sys {
    background-color: #233278;
    padding: 80px 0;
    ul {
        li:first-child {
            padding-right: 90px;
            text-align: right;
            margin-bottom: 120px;
                i{
                    right: 0;
                    top: 0;
                }
        }
        li:nth-child(2) {
                float: right;
                margin-bottom: 120px;
                padding-left: 90px;
                text-align: left;
                i{
                    left: 0;
                    top: 0;
                }
        }
        li:nth-child(3)    {
            padding-right: 90px;
             text-align: right;
                i{
                    right: 0;
                    top: 0;
                }
        }
        li:nth-child(4) {
                float: right;
                padding-left: 90px;
                text-align: left;
                i{
                    left: 0;
                    top: 0;
                }
        }
        li {
            width: 30%;
            float: left;
            position: relative;
            padding-top: 15px;
        }
    }
    .txt {
        .tit {
            font-size: 32px;
            color: #fff;
        }
        .note {
            font-size: 16px;
            color: #999;
            word-break: break-all;
            word-wrap: break-word;
            white-space: pre-wrap;
        }
    }
    .tex {
        display: block;
        .tit {
            font-size: 20px;
            transition: .2s all;
            color: #fff;
            cursor: pointer;
            
        }
        .note {
                color: rgba(255,255,255,.6);
                font-size: 14px;
        }
    }
}
.ld { 
    padding: 80px 0;
    background:#233278;
        .ue-title {
        font-size: 32px;
        margin-bottom:80px;
        color: #fff;
        .note {
              font-size: 16px;
              color: #999;
              word-break: break-all;
              word-wrap: break-word;
              white-space: pre-wrap;
              margin-top: 5px;
        }
    }  
        .pt-list {
            margin-top: 30px;
        ul {
            display: -webkit-flex;
            display: flex;
            align-content: space-around;
            -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
            -webkit-align-content: center;
            align-content: center;
            justify-content: space-between;
        li {
            padding: 10px 0;
            list-style: none;
            width: 30%;
            display: block;
            text-align: center;
            margin-bottom: 25px;
            img {
              height: 75px;
              width: 75px;
            }
                .txt{
                    // padding:20px;
                .tit {
                        font-size: 20px;
                        margin-top: 5px;
                        margin-bottom:15px;
                }
                .note {    
                    font-size: 16px;
                    color: #999;
                    max-width: 78%;
                    text-align: left;
                    margin: 0 auto;
                }
            }
        }
        li:hover {
          box-shadow:0 1px 2px rgba(0,0,0,.1)
        }
        }
    
    }
}
.zheys-list {
    margin-top: 40px;
    .teck-advantage-center {
        position: absolute;
        top: 40%;
        left: 50%;
        width: 219px;
        height: 219px;
        margin-left: -110px;
        border-radius:50% ;
        background: url('../assets/img/1.png') center center no-repeat ;
        background-size:80% 80% ;
        i {
            display: block;
            height: 100%;
            width: 100%;
            img {
                animation:rot_test 3s infinite linear
            }
            @keyframes rot_test {
                0%{
                    transform: rotate(360deg);
                }    
                100%{
                    transform: rotate(0deg);
                }        
            }
        }
    }
    ul{
        li {
            i {
                position: absolute;
            }
            .sb2 .sb4 {
                i{
                left:0;
                top: 0;
                }
            }
            .sb1 .sb3 {
                right: 0;
                top: 0;
            }
            .note {
                font-size: 14px;
            }
        }
    }
}
.xtgn {
    padding: 80px 0;
    .xt {
        .title {
            font-size: 32px;
        }
        .xggn-list {
            margin-top: 30px;
        ul {
            display: -webkit-flex;
            display: flex;
            align-content: space-around;
            -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
            -webkit-align-content: center;
            align-content: center;
            justify-content: space-between;
        li {
            padding: 10px 0;
            list-style: none;
            width: 29%;
            display: block;
            text-align: center;
            margin-bottom: 25px;
                .txt{
                    padding:20px;
                .tit {
                        font-size: 20px;
                        margin-top: 5px;
                        margin-bottom: 4px;
                }
                .note {    
                    font-size: 14px;
                    color: #999;
                    max-width: 78%;
                    margin: 0 auto;
                }
            }
        }
        }
    }
    }
}
.xtjg {
    background-color: #fff;
    padding: 80px 0;
    .title {
        font-size: 32px;
    }
    .danimg {
        margin-top: 40px;
        text-align: center;
        img {
            width: 100%;
        }
    } 
}
.xttd {
    padding:80px 0;
    background: #233278;
    .ue-title {
        font-size: 32px;
        color: #fff;
    }
    .tdlist {
        margin-top: 20px;
        ul {
            display: -webkit-flex;
            display: flex;
            align-content: space-around;
            -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
            -webkit-align-content: center;
            align-content: center;
            justify-content: space-between;
            li {
                list-style: none;
                padding: 20px;
                width: 29%;
                text-align: left;
                position: relative;
                margin-bottom: 20px;
                .item {
                    padding-left: 50px;
                }
                i{
                    display: inline-block;
                    position: absolute;
                    left: 0;
                }
                .tit {
                    font-size: 18px;
                    margin-bottom: 4px;
                    color: #fff;
                }
                .note {
                    font-size: 12px;
                    color: #999;
                    line-height: 20px;
                }
            }
        }
    }
}
.xtyy {
    padding: 80px 0;
    background: #fff;
    .title {
        font-size: 32px;
    }
    .danimg {
        margin-top: 40px;
        text-align: center;
        img {
            width: 100%;
        }
    } 
}
.znbj {
    padding: 80px 0;
    background-color: #f5f5f5;

    position: relative;
    .ue-title {
        font-size: 32px;
    }
    .znbjer {
        margin-top: 20px;
        position: relative;
    .left {
        width: 22.5%;
        background-color: #242424 ;
        position: relative;
        ul {
            li {
                list-style: none;
                padding: 22px;
                p {
                    color: #fff;
                    margin-top: 5px;
                }
            }
        }
    }
    .right {
        position: absolute;
        right: 0;
        top: 0;
        width: 78.5%;
        height: 100%;
    }
    }

}
.el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 200px;
    margin: 0;
}
  
.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}
  
  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
}
.product {
    padding: 80px 0;
    .ue-title {
        font-size: 32px;
    }   
    .productList {
        margin-top: 20px;
        ul {
            display: -webkit-flex;
            display: flex;
            align-content: space-around;
            -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
            -webkit-align-content: center;
            align-content: center;
            justify-content: space-between;
        li {
            padding: 10px 0;
            list-style: none;
            width: 23%;
            a{
                display: block;
                background: #fff;
                text-align: center;
                border: 1px solid #fff;
                overflow: hidden;
                text-decoration: none;
                img {
                    transition: all .7s;
                    height: 175px;
                }
                img:hover {
                    transform: scale(1.2);
                    }
                .txt{
                    padding:20px;
                .tit {
                        font-size: 16px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        margin-bottom: 4px;
                        color: #333;
                }
                .note {
                        font-size: 12px;
                        margin-bottom: 6px;
                        color: #999;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                }}
            }
          
            a:hover {
                    border: 1px solid #0e90d2;
                }
        }
        }
    }
    .more {
        align-content: center;
        margin-top:30px ;
        a {
            display: inline-block;
            padding: 8px 60px;
            font-size: 20px;
            border: 1px solid #ddd;
            text-decoration: none;
        }
        a:hover {
            background: #2278C7;
            color: #fff;

        }
    }
}
.homed {
    text-align: center;
    font-size: 24px;
    color: #fff;
    padding: 3% 0;
    background: url('~images/adbg.jpg') center no-repeat;
    background-size: cover;
}
.on {
    background: #1162fe!important
}
</style>